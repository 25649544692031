import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { supabase } from './supabase/supabase';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const strongColors = [
  '#2CA341',
  '#FF5733', // Rojo fuerte
  '#3357FF', // Azul fuerte
  '#FF33A1', // Rosa fuerte
  '#33FFF4', // Cian fuerte
  '#FF9F33', // Naranja fuerte
];

const trimestreLabels = {
  '1': 'I TRIMESTRE',
  '2': 'II TRIMESTRE',
  '3': 'III TRIMESTRE',
  '4': 'IV TRIMESTRE'
};

const processMeses = (meses) => {
  const parsedMeses = JSON.parse(meses);
  const processedMeses = {};
  for (let key in parsedMeses) {
    if (trimestreLabels[key]) {
      processedMeses[trimestreLabels[key]] = parsedMeses[key];
    } else {
      processedMeses[key] = parsedMeses[key];
    }
  }
  return processedMeses;
};

function App() {
  const [scrolled, setScrolled] = useState(false);
  const [sectores, setSectores] = useState([]);
  const [sectorSeleccionado, setSectorSeleccionado] = useState(null);
  const selectedSectorRef = useRef(null);
  const [selectedIndicador, setSelectedIndicador] = useState('');
  const [indicadores, setIndicadores] = useState([]);
  const [opcionesIndicador, setOpcionesIndicador] = useState([]);
  const [selectedOpciones, setSelectedOpciones] = useState('');
  const [dataGrafico, setDataGrafico] = useState(null);
  const [dataGraficoUnits, setDataGraficoUnits] = useState(null);
  const [measurementUnits, setMeasurementUnits] = useState('');
  const [idOpciones, setIdOpciones] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchSectores = async () => {
      try {
        const { data, error } = await supabase.from('sectores').select('*');
        if (error) throw error;
        setSectores(data);
      } catch (error) {
        console.error('Error al obtener los sectores:', error.message);
      }
    };
    fetchSectores();
  }, []);

  useEffect(() => {
    handleOpcionesChange("");
  }, []);

  const handleClick = async (nombreSector) => {
    setDataGrafico(null);
    const sector = sectores.find(sector => sector.name === nombreSector);
    if (sector) {
      setSectorSeleccionado(sector);
      try {
        const { data, error } = await supabase
          .from('rel_sectores_indicadores')
          .select(`*,indicadores(name)`)
          .eq('id_sectores', sector.id);
        if (error) throw error;
        const indicadoresData = data.map((rel) => rel.indicadores.name);
        setIndicadores(indicadoresData);
      } catch (error) {
        console.error('Error al obtener los indicadores:', error.message);
      }
      if (selectedSectorRef.current) {
        selectedSectorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      console.log("No se encontró el sector en la tabla sectores.");
    }
  };

  const handleIndicadorChange = async (selectValue) => {
    setDataGrafico(null);
    try {
      const { data: Indicador, error } = await supabase
        .from('indicadores')
        .select('id')
        .eq('name', selectValue);
      if (error) throw error;
      if (Indicador.length > 0) {
        const indicadorId = Indicador[0].id;
        setSelectedIndicador(indicadorId);

        const { data: generalData, error: generalError } = await supabase
          .from('general')
          .select('meses, measurement_units, id_periodicidad, year')
          .eq('id_sectores', sectorSeleccionado.id)
          .eq('id_indicadores', indicadorId)
          .eq('id_opciones_indicadores3', '0');
        if (generalError) throw generalError;
        if (generalData.length > 0) {
          const uniqueLabels = new Set();
          const datasets = [];
          const units = generalData[0].measurement_units;
          const parsedData = generalData.map(item => ({
            year: item.year,
            meses: processMeses(item.meses)  // Process the 'meses' here
          }));
          const { id_periodicidad, measurement_units } = generalData[0];
          const { data: periodicidadData, error: periodicidadError } = await supabase
            .from('periodicidad')
            .select('name')
            .eq('id', id_periodicidad);
          if (periodicidadError) throw periodicidadError;
          const periodicidadName = periodicidadData[0].name;

          parsedData.sort((a, b) => a.year - b.year);
          parsedData.forEach(({ year, meses }) => {
            const labels = Object.keys(meses);
            const units = generalData[0].measurement_units;
            setMeasurementUnits(units);
            labels.forEach(label => uniqueLabels.add(label));
            const valores = Object.values(meses).map(val => val.toString());
            const color = strongColors[datasets.length % strongColors.length];
            const dataset = {
              backgroundColor: color,
              borderColor: color,
              borderWidth: 2,
              data: valores
            };
  
            if (year) {
              dataset.label = `Valores (${year})`;
            }
            else {
              dataset.label = "Valores";
            }
  
            datasets.push(dataset);
          });
          const data = {
            labels: Array.from(uniqueLabels),
            datasets: datasets
          };
          const options = {
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: `Valores (${units})`,
                },
              },
              grid: {
                display: false,  // Elimina las líneas de la cuadrícula en el eje y
              },
              x: {
                title: {
                  display: true,
                  text: 'Meses'
                },
                grid: {
                  display: false,  // Elimina las líneas de la cuadrícula en el eje x
                },
              }
            },
            plugins: {
              title: {
                display: true,
                text: `Gráfico ${periodicidadName}`,
                font: {
                  size: 24
                },
                color: 'green',
                fontFamily: 'Segoe UI'
              }
            }
          };
          setDataGrafico(data);
          setDataGraficoUnits(options);
          setMeasurementUnits(units);
          setOpcionesIndicador([]);
        } else {
          try {
            const { data: opcionesInd, error } = await supabase
              .from('rel_indicadores_indicadores_opciones3')
              .select(`*,indicadores_opciones3(name)`)
              .eq('id_indicadores', indicadorId);
            if (error) throw error;
            const opcionesData = opcionesInd.map((rel) => rel.indicadores_opciones3.name);
            setOpcionesIndicador(opcionesData);
          } catch (error) {
            console.error('Error al obtener las opciones de indicadores:', error.message);
          }
        }
      }
    } catch (error) {
      console.error('Error al obtener los indicadores:', error.message);
    }
  };
  useEffect(() => {
    console.log("lo que llega", idOpciones);
  }, [idOpciones]);


  const handleOpcionesChange = async (selectValue) => {
    setDataGrafico(null);
    try {
      let generalData;
      console.log("Realizando consulta con opciones de indicadores...", selectValue);
      try {
        const { data: idOpcion, error: idOpcionError } = await supabase
          .from('indicadores_opciones3')
          .select('id')
          .eq('name', selectValue);
        if (idOpcionError) throw idOpcionError;
        console.log("el id", idOpcion[0].id);
        setIdOpciones(idOpcion[0].id);
        const { data, error: generalError } = await supabase
          .from('general')
          .select('meses, measurement_units, id_periodicidad, year')
          .eq('id_sectores', sectorSeleccionado.id)
          .eq('id_indicadores', selectedIndicador)
          .eq('id_opciones_indicadores3', idOpcion[0].id);
        if (generalError) throw generalError;
        generalData = data;
      } catch (error) {
        console.error('Error al obtener los datos de la tabla general:', error.message);
        return;
      }
      if (generalData.length > 0) {
        const uniqueLabels = new Set();
        const datasets = [];
        const units = generalData[0].measurement_units;
        const parsedData = generalData.map(item => ({
          year: item.year,
          meses: processMeses(item.meses)  // Process the 'meses' here
        }));
        const { id_periodicidad, measurement_units } = generalData[0];
        const { data: periodicidadData, error: periodicidadError } = await supabase
          .from('periodicidad')
          .select('name')
          .eq('id', id_periodicidad);
        if (periodicidadError) throw periodicidadError;
        const periodicidadName = periodicidadData[0].name;

        parsedData.sort((a, b) => a.year - b.year);
        parsedData.forEach(({ year, meses }) => {
          const labels = Object.keys(meses);
          const units = generalData[0].measurement_units;
          setMeasurementUnits(units);
          labels.forEach(label => uniqueLabels.add(label));
          const valores = Object.values(meses).map(val => val.toString());
          const color = strongColors[datasets.length % strongColors.length];
          const dataset = {
            backgroundColor: color,
            borderColor: color,
            borderWidth: 2,
            data: valores
          };

          if (year) {
            dataset.label = `Valores (${year})`;
          }
          else {
            dataset.label = "Valores";
          }

          datasets.push(dataset);

        });
        const data = {
          labels: Array.from(uniqueLabels),
          datasets: datasets
        };
        const options = {
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: `Valores (${units})`,
              },
              grid: {
                display: false,  // Elimina las líneas de la cuadrícula en el eje x
              },
            },
            x: {
              title: {
                display: true,
                text: 'Meses'
              },
              grid: {
                display: false,  // Elimina las líneas de la cuadrícula en el eje x
              },
            }
          },
          plugins: {
            title: {
              display: true,
              text: `Gráfico ${periodicidadName}`,
              font: {
                size: 24
              },
              color: 'green',
              fontFamily: 'Segoe UI'
            }
          }
        };
        setDataGrafico(data);
        setDataGraficoUnits(options);
        setMeasurementUnits(units);
      }
    } catch (error) {
      console.error('Error al obtener los datos de la tabla general:', error.message);
    }
  };


  return (
    <div>
      <nav className={`h-[100px] px-10 ${scrolled ? 'scrolled' : ''}`}>
        <div className="logo">
          <img src="./images/logoOf.webp" alt="Logo" />
        </div>
        <ul>
          {/* Listado de elementos de navegación */}
        </ul>
      </nav>
      {/* Sección de la imagen de fondo y título */}
      <section className='h-screen relative'>
        <img src='./images/1.png' className="w-full h-full object-cover" />
        <h1 className='absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 '>Indicadores económicos <br /><span>de la Región Arequipa</span></h1>
      </section>
      {/* Sección de los sectores */}
      <section className='relative bg-white flex justify-center items-center'>
        <section id="hero" className="container items-center m-20">
          <div className="row grid grid-cols-3 gap-10 flex items-center self-center content-center place-items-center mt-0 pt-0">
            {sectores.map((sector, index) => (
              <div key={index} className="col-md-4">
                <div className="flex flex-col items-center justify-content-center max-w-full">
                  <a href="#options" className="py-4 btn btn-primario" onClick={() => handleClick(sector.name)}>
                    <div className="flex items-center justify-content-center">
                      <img src={`./images/${sector.imagen}`} alt={sector.name} />
                    </div>
                    <div className="flex items-center justify-content-center">
                      <span>{sector.name}</span>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </section>
      </section>
      {/* Sección de los indicadores y gráficos */}
      {sectorSeleccionado && (
        <section ref={selectedSectorRef} className="relative bg-white grid justify-center m-20">
          <div className="container justify-center h-full">
            <h2 className="text-center">{`Indicadores económicos de ${sectorSeleccionado.name}`}</h2>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3">
              <div className="max-h-screen mt-20">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                  Indicadores
                </label>
                <div className="relative">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                    onChange={(e) => handleIndicadorChange(e.target.value)}
                  >
                    <option value="">Selecciona una opción</option>
                    {indicadores.map((indicador) => (
                      <option key={indicador} value={indicador}>
                        {indicador}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            {(selectedIndicador && selectedIndicador !== '0' && opcionesIndicador.length > 0) && (
              <div className="w-full md:w-1/2 px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 max-h-screen mt-20" htmlFor="grid-state">
                  Opciones de indicadores
                </label>
                <div className="relative">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                    onChange={(e) => handleOpcionesChange(e.target.value)}
                  >
                    <option value="">Selecciona una opción</option>
                    {opcionesIndicador.map((opciones) => (
                      <option key={opciones} value={opciones}>
                        {opciones}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            )}
          </div>

          {dataGrafico && dataGraficoUnits && (
            <div className="container mx-auto mt-10">
              <Bar data={dataGrafico} options={dataGraficoUnits} />
            </div>
          )}
        </section>
      )}
    </div >
  );
}

export default App;